<template>
  <div class="group-1">
    <div class="february-20th-1 valign-text-left robotoslab-normal-white-54px">MARCH 22nd, 2022</div>
    <div class="doge-frens-launch-day-1 valign-text-left robotoslab-normal-white-36px">$DGOLD and DAO will be launched. You can earn $DGOLD by staking your NFTs.</div>
  </div>
</template>

<script>
export default {
  name: "Group17",
};
</script>

<style>
.group-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 118px;
  width: 423px;
}

.february-20th-1 {
  height: 71px;
  letter-spacing: 0;
  min-width: 499px;
  text-align: center;
}

.doge-frens-launch-day-1 {
  height: 170px;
  letter-spacing: 0;
  min-width: 499px;
  text-align: center;
}
</style>
