<template>
  <div class="group-1">
    <div class="february-20th-1 valign-text-left robotoslab-normal-white-54px">MARCH 13TH, 2022</div>
    <div class="doge-frens-launch-day-1-15 valign-text-left robotoslab-normal-white-36px">Doge Frens join the universe. Helping Dogelifes build a greater community!</div>
  </div>
</template>

<script>
export default {
  name: "Group15",
};
</script>

<style>
.group-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 118px;
  width: 423px;
}

.february-20th-1 {
  height: 51px;
  letter-spacing: 0;
  min-width: 469px;
  text-align: center;
}

.doge-frens-launch-day-1-15 {
  height: 120px;
  letter-spacing: 0;
  min-width: 469px;
  text-align: center;
  /* margin-top: -50px; */
}
</style>
