<template>
  <div class="group-1">
    <div class="february-20th-1 valign-text-left robotoslab-normal-white-54px">MARCH 15th, 2022</div>
    <div class="doge-frens-launch-day-1 valign-text-left robotoslab-normal-white-36px">Happy Birthday DogeLife!! You can migrate your DogeLife NFTs into a new ERC721 smart contract.</div>
  </div>
</template>

<script>
export default {
  name: "Group16",
};
</script>

<style>
.group-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 118px;
  width: 423px;
}

.february-20th-1 {
  height: 71px;
  letter-spacing: 0;
  min-width: 469px;
  text-align: center;
}

.doge-frens-launch-day-1 {
  height: 160px;
  letter-spacing: 0;
  min-width: 469px;
  text-align: center;
}
</style>
