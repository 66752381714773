<template>
  <div class="group-1">
    <div class="february-20th-1 valign-text-left robotoslab-normal-white-54px">APRIL 1st, 2022</div>
    <div class="doge-frens-launch-day-1-18 valign-text-left robotoslab-normal-white-36px">THE DOGEVERSE ALPHA PLUS ROADMAP 2.0 (It’s not an April joke.)</div>
  </div>
</template>

<script>
export default {
  name: "Group18",
};
</script>

<style>
.group-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 118px;
  width: 423px;
}

.february-20th-1 {
  height: 71px;
  letter-spacing: 0;
  min-width: 499px;
  text-align: center;
}

.doge-frens-launch-day-1-18 {
  height: 120px;
  letter-spacing: 0;
  min-width: 499px;
  text-align: center;
}
</style>
